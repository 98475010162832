import React, { Component } from "react";
import { config } from "react-spring";
import "./examplee.css";
import '../Carouselee/portfolio-sub/Ev.css'
import '../Carouselee/portfolio-sub/Photo.css'
import '../Carouselee/portfolio-sub/Web.css'
import '../Carouselee/portfolio-sub/Pay-per.css'
import HorizontalCarousel from './VerticalCarousele'; // Correct path
import SliderLine from './SliderSizess'; // Correct path

import './Krgallery.css'
import './portfolio-sub/graphic-design.css'
import r1 from '../../../images/g1.png';
import r2 from '../../../images/g2.png';
import r3 from '../../../images/g3.png';
import r4 from '../../../images/g4.png';
import r5 from '../../../images/g5.png';
import r6 from '../../../images/g6.png';
import r7 from '../../../images/g7.png';
import r8 from '../../../images/g8.png';
import r9 from '../../../images/g9.png';
import r10 from '../../../images/g10.png';
import r11 from '../../../images/g11.png';
import r12 from '../../../images/g12.png';
import r13 from '../../../images/g13.png';
import r14 from '../../../images/g14.png';
import r15 from '../../../images/g15.png';
import r16 from '../../../images/g16.png';
import r17 from '../../../images/g17.png';
import r18 from '../../../images/g18.png';
import r19 from '../../../images/g19.png';
import r20 from '../../../images/g20.png';
import r21 from '../../../images/g21.png';
import r22 from '../../../images/g21.png';
import r23 from '../../../images/g22.png';
import r24 from '../../../images/g23.png';
import r25 from '../../../images/g24.png';
import r26 from '../../../images/beer.png';
import r27 from '../../../images/g25.png';
import r29 from '../../../images/beer.png';
import r30 from '../../../images/beer.png';
import web1 from '../../../images/web-1.png'
import web2 from '../../../images/web-2.png'
import web3 from '../../../images/web-3.png'
import web4 from '../../../images/web-4.png'
import web5 from '../../../images/web-5.png'
import web6 from '../../../images/web-6.png'
import web7 from '../../../images/web-7.png'
import web8 from '../../../images/web-8.png'

import per2 from '../../../images/per-2.png'
import per3 from '../../../images/per-3.png'
import per4 from '../../../images/per-4.png'
import per5 from '../../../images/per-5.png'

import perL2 from '../../../images/per-l2.png'
import perL3 from '../../../images/per-l3.png'
import perL4 from '../../../images/per-l4.png'
import perL5 from '../../../images/per-l5.png'
import ev1 from '../../../images/ev-1.png'
import ev2 from '../../../images/ev-2.png'
import ev3 from '../../../images/ev-3.png'
import ev4 from '../../../images/ev-4.png'
import ev5 from '../../../images/ev-5.png'
import ev6 from '../../../images/ev-66.png'
import ev7 from '../../../images/ev-7.png'
import ev8 from '../../../images/ev-8.png'
import beer from '../../../images/beer.png'
import bg2 from '../../../images/bg-text2.svg'

import pv1 from '../../../images/pv1.png'
import pv2 from '../../../images/pv2.png'
import pv3 from '../../../images/pv3.png'
import pv4 from '../../../images/pv4.png'
import pv5 from '../../../images/pv5.png'
import pv6 from '../../../images/pv6.png'
import pv7 from '../../../images/pv7.png'
import pv8 from '../../../images/pv8.png'
import pv9 from '../../../images/pv9.png'
import texvid from '../../../vedios/tex.mp4'
import mahvid from '../../../vedios/maha.mp4'

import trimaia1 from "../../../images/trimaia 1.png"
import trimaia2 from "../../../images/trimaia 2.png"
import trimaia3 from "../../../images/trimaia 3.png"
import nss1 from "../../../images/nss 1.png"
import nss2 from "../../../images/nss 2.png"
import naanal1 from "../../../images/naanal 1.png"
import naanal2 from "../../../images/naanal 2.png"
import naanal3 from "../../../images/naanal 3.png"
import laxmi1 from "../../../images/laxmi 1.png"
import laxmi2 from "../../../images/laxmi 2.png"
import maz1 from "../../../images/maz 1.png"
import maz2 from "../../../images/maz 2.png"
import wise1 from "../../../images/wise 1.png"
import wise2 from "../../../images/wise 2.png"
import wise3 from "../../../images/wise 3.png"
import apr1 from "../../../images/apr 1.png"
import apr2 from "../../../images/apr 2.png"
import ana1 from "../../../images/ana 1.png"
import ana2 from "../../../images/ana 2.png"
import jat1 from "../../../images/jat 1.png"
import jat2 from "../../../images/jat 2.png"
import ssv1 from "../../../images/ssv 1.png"
import ssv2 from "../../../images/ssv 2.png"
import gr1 from "../../../images/gr 1.png"
import gr2 from "../../../images/gr 2.png"
import Swasthik from "./resource/Swasthik";
import Reva from "./resource/Reva";
import KK from "./resource/Kk";
import Video from "./resource/Video";
import Videos from "./resource/Video";
import { OurCommunity } from "../../Page1/OurCommunity/OurCommunity";
import Footerel from "../../Page2/Lfooter/Lfooter";
import Omb_cr from "./omb/Omb_cr";
import { Link } from "react-router-dom";
import App from "../../../App";
import Appe from "./omb/Modal";








const slides = [
  {
    key: 1,
    content: (
      <div className="key-2"   >
        <div className="webdesign-section">
          <div class="row first-row" >
            <div class="col-6 col-sm-3 order-sm-2 order-1 poster-padding-col">
              <img className="web-img" src={trimaia1} />
            </div>
            <div class="col-12 col-sm-6 order-sm-1 order-2 posters-col">
              <img className="web-img" src={trimaia2} />
            </div>
            <div class="col-6 col-sm-3 poster-padding-col">
              <img className="web-img" src={trimaia3} />
            </div>
          </div>
          <div class="row second-row" >
            <div class="col-8 poster-padding-col">
              <img className="design-img" src={nss1} />
            </div>
            <div class="col-4 poster-padding-col">
              <img className="design-img" src={nss2} />
            </div>
          </div>
          <div class="row first-row" >
            <div class="col-4  poster-padding-col">
              <img className="design-img" src={naanal1} />
            </div>
            <div class="col-4 poster-padding-col">
              <img className="design-img" src={naanal2} />
            </div>
            <div class="col-4 poster-padding-col">
              <img className="design-img" src={naanal3} />
            </div>
          </div>
          <div class="row second-row" >
            <div class="col-8 poster-padding-col">
              <img className="design-img" src={laxmi1} />
            </div>
            <div class="col-4 poster-padding-col">
              <img className="design-img" src={laxmi2} />
            </div>
          </div>
          <div class="row first-row" >
            <div class="col-4 poster-padding-col">
              <img className="design-img" src={maz1} />
            </div>
            <div class="col-8 poster-padding-col">
              <img className="design-img" src={maz2} />
            </div>
          </div>
          <div class="row second-row" >
            <div class="col-7 poster-padding-col">
              <img className="design-img" src={wise1} />
            </div>
            <div class="col-3 poster-padding-col">
              <img className="design-img" src={wise2} />
            </div>
            <div class="col-2 poster-padding-col">
              <img className="design-img" src={wise3} />
            </div>
          </div>
          <div class="row first-row" >
            <div class="col-4 poster-padding-col">
              <img className="design-img" src={apr1} />
            </div>
            <div class="col-8 poster-padding-col">
              <img className="design-img" src={apr2} />
            </div>
          </div>
          <div class="row second-row" >
            <div class="col-8 poster-padding-col">
              <img className="design-img" src={ana1} />
            </div>
            <div class="col-4 poster-padding-col">
              <img className="design-img" src={ana2} />
            </div>
          </div>
          <div class="row first-row" >
            <div class="col-4 poster-padding-col">
              <img className="design-img" src={jat1} />
            </div>
            <div class="col-8 poster-padding-col">
              <img className="design-img" src={jat2} />
            </div>
          </div>
          <div class="row second-row" >
            <div class="col-8 poster-padding-col">
              <img className="design-img" src={ssv1} />
            </div>
            <div class="col-4 poster-padding-col">
              <img className="design-img" src={ssv2} />
            </div>
          </div>
          <div class="row first-row">
            <div class="col-6 poster-padding-col">
              <img className="design-img" src={gr1} />
            </div>
            <div class="col-6 poster-padding-col">
              <img className="design-img" src={gr2} />
            </div>
          </div>
        </div>
      <OurCommunity/>
      <Footerel/>
      

      </div>
    )
  },
  {
    key: 2,
    content: (
      <div className="key-2 " id="top" >
        <div className=" service-container2">
          <div className="row web-sec" >
            <div className="col-12">
            <Link to='https://www.oshobodybuilders.in/'><img className="web-img" src={web1} /></Link>
            </div>
            <div className="col-12 osho-text">
              <h4 className="web-h">Osho Body Builders</h4>
              <p className="web-p">Osho Body Builders, a leader in custom commercial vehicle bodies, partnered with us to develop a dynamic website using React. The platform highlights their expertise and features a robust CMS for streamlined content management.</p>
            </div>

          </div>
        </div>

        <div className=" service-container3" >
          <div className="row web-sec">
            <div className="col-12">
            <Link to='https://mrdistributionservicescbe.com/'><img className="web-img" src={web2} /></Link>
            </div>
            <div className="col-12 mr-text">
              <h4 className="web-h">MR Distribution Services</h4>
              <p className="web-p">MR Distribution Services, based in RS Puram, trusted us to create a digital platform that reflects their commitment to providing diverse investment opportunities. Through React development, we built a user-centric website that simplifies the investment journey. </p>
            </div>

          </div>
        </div>

        <div className=" service-container4">
          <div className="row web-sec">
            <div className="col-12">
            <Link to='https://jattechno.com/'><img className="web-img" src={web3} /></Link>
            </div>
            <div className="col-12 osho-text">
              <h4 className="web-h">Jat Techno Spintex Mills</h4>
              <p className="web-p">JAT Techno Spintex Mill, a textile manufacturing leader, required a strong online presence. We built a WordPress website equipped with a CMS to effectively showcase their expertise in transforming raw materials into high-quality textile products.</p>
            </div>

          </div>
        </div>

        <div className=" service-container5">
          <div className="row web-sec">
            <div className="col-12">
            <Link to='https://www.rrthoranammahal.com/'><img className="web-img" src={web4} /></Link>
            </div>
            <div className="col-12 mr-text">
              <h4 className="web-h">RR Thoranam Mahal</h4>
              <p className="web-p">RR Thoranam Mahal, a Coimbatore-based premier wedding venue, required a digital platform that would captivate potential clients. We delivered a seamless React-powered website that lets potential clients easily explore options for unforgettable celebrations</p>
            </div>

          </div>
        </div>

        <div className=" service-container6">
          <div className="row web-sec">
            <div className="col-12">
            <Link to='https://gowthamarchitects.com/'><img className="web-img" src={web5} /></Link>
            </div>
            <div className="col-12 osho-text">
              <h4 className="web-h">Gowtham Architects</h4>
              <p className="web-p">Gowtham Architects, a top Coimbatore firm with a 40-years of legacy. We partnered with them to build a WordPress website enhanced with GSAP animations, showcasing their impressive portfolio and providing a dynamic user experience.</p>
            </div>

          </div>
        </div>


        <div className=" service-container7">
          <div className="row web-sec">
            <div className="col-12">
              <Link to='https://bankvallet.com/'><img className="web-img" src={web6} /></Link>
            </div>
            <div className="col-12 mr-text">
              <h4 className="web-h">Bankvallet</h4>
              <p className="web-p">Bankvallet, a fintech pioneer, simplifies loans by assessing eligibility and connecting customers with lenders. We created a WordPress website that aligns with their mission, providing a reliable platform to optimize loan approvals and enhance customer satisfaction.</p>
            </div>

          </div>
        </div>

        <div className=" service-container8">
          <div className="row web-sec">
            <div className="col-12">
            <Link to='https://srimurugavilassweets.in/'><img className="web-img" src={web7} /></Link>
            </div>
            <div className="col-12 osho-text pb-5">
              <h4 className="web-h">Sri Muruga Vilas Sweets</h4>
              <p className="web-p">Sri Muruga Vilas Sweets is renowned for its delectable range of over 12 traditional Indian sweets and savories. We created a WordPress website that showcases their rich heritage and commitment to quality, inviting customers to indulge in their sweet delights.</p>
            </div>

          </div>
        </div>

        <div className=" service-container7">
          <div className="row web-sec">
            <div className="col-12">
            <Link to='https://gearsandpiston.com/'><img className="web-img" src={web8} /></Link>
            </div>
            <div className="col-12 mr-text">
              <h4 className="web-h">Gears and Piston</h4>
              <p className="web-p">Gears and Piston offers top-notch bike servicing, from water and foam washes to comprehensive tune-ups. Our React-powered website provides a seamless user experience, making it easy to book their services.
              </p>
            </div>

          </div>
        </div>
        
        <OurCommunity/>
      <Footerel/>

      </div>


    )
  },
  {
    key: 3,
    content: (
      <div className="key-3" id="top">
        <div className="service-container9">
          {/* <div className="row pay-per-main">
            <div className="col-12 col-sm-6 pay-per-img-head">
              <img className="pay-per-img" src={per1} />
              <video className="pay-per-video" src={swsthik}/>
            </div>
            <div className="col-12 col-sm-6 pay-text-col">
              <img className="pay-per-logo" src={perL1} />
              <h3 className="pay-per-head">Engagement Ad </h3>
              <p className="pay-per-para">Swasthik Architects, a leading architectural firm, aimed to increase qualified leads. Our PPC campaign focused on generating high-quality leads by targeting potential clients actively seeking architectural services.</p>
              <div className="pay-sub">
                <div className="pay-sub-box">
                  <h3 className="pay-sub-head">30,000+</h3>
                  <h6 className="pay-sub-p">Reaches</h6>
                </div>
                <div className="pay-sub-box">
                  <h3 className="pay-sub-head">45,000+</h3>
                  <h6 className="pay-sub-p">Impressions</h6>
                </div>
                <div className="pay-sub-box">
                  <h3 className="pay-sub-head">30+</h3>
                  <h6 className="pay-sub-p"> Results</h6>
                </div>
              </div>
            </div>
          </div> */}
          <Swasthik />

          <div className="row pay-per-main  pay-vid-pd">
            <div className="col-0 col-sm-1"></div>
            <div className="col-12 col-sm-5 order-2  order-sm-1 pay-text-col">
              <img className="pay-per-logo" src={perL2} />
              <h3 className="pay-per-head">Traffic ad </h3>
              <p className="pay-per-para">On My Body by Shreya, a premium fashion brand, aimed to increase online visibility and drive traffic to their website. Our campaign delivered impressive results, using captivating visuals and strategic keywords to attract fashion-forward shoppers </p>
              <div className="pay-sub">
                <div className="pay-sub-box">
                  <h3 className="pay-sub-head">80,000</h3>
                  <h6 className="pay-sub-p">Reaches</h6>
                </div>
                <div className="pay-sub-box">
                  <h3 className="pay-sub-head">95,000</h3>
                  <h6 className="pay-sub-p">Impressions</h6>
                </div>
                <div className="pay-sub-box">
                  <h3 className="pay-sub-head">1000+</h3>
                  <h6 className="pay-sub-p"> Results</h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 order-1  order-sm-2 pay-per-img-head2">
              <img className="pay-per-img2" src={per2} />
            </div>
          </div>
          


          {/* <div className="row pay-per-main pt-5">
            <div className="col-12 col-sm-6 pay-per-img-head">
              <img className="pay-per-img" src={per3} />
            </div>
            <div className="col-12 col-sm-6 pay-text-col">
              <img className="pay-per-logo" src={perL3} />
              <h3 className="pay-per-head">Engagement Ad </h3>
              <p className="pay-per-para">Reva Steel Doors and Windows sought to build stronger relationships with potential customers in a short period. Our engagement-focused campaign used captivating visuals and interactive elements to spark interest, drive conversations, and boost brand loyalty.</p>
              <div className="pay-sub">
                <div className="pay-sub-box">
                  <h3 className="pay-sub-head">2000+ </h3>
                  <h6 className="pay-sub-p">Reaches</h6>
                </div>
                <div className="pay-sub-box">
                  <h3 className="pay-sub-head">3000+</h3>
                  <h6 className="pay-sub-p">Impressions</h6>
                </div>
                <div className="pay-sub-box">
                  <h3 className="pay-sub-head">30+</h3>
                  <h6 className="pay-sub-p"> Results</h6>
                </div>
              </div>
            </div>
          </div> */}
          <Reva />

          <div className="row pay-per-main  pay-vid-pd ">
            <div className="col-0 col-sm-1"></div>
            <div className="col-12 col-sm-5  order-2  order-sm-1 pay-text-col">
              <img className="pay-per-logo" src={perL4} />
              <h3 className="pay-per-head">Lead ad
              </h3>
              <p className="pay-per-para">Zyme Restaurant aimed to increase foot traffic and drive in-store sales. Our lead generation campaign focused on enticing potential customers with irresistible offers and highlighting the restaurant's unique dining experience.</p>
              <div className="pay-sub">
                <div className="pay-sub-box">
                  <h3 className="pay-sub-head"> 25,000</h3>
                  <h6 className="pay-sub-p">Reaches</h6>
                </div>
                <div className="pay-sub-box">
                  <h3 className="pay-sub-head"> 60,000</h3>
                  <h6 className="pay-sub-p">Impressions</h6>
                </div>
                <div className="pay-sub-box">
                  <h3 className="pay-sub-head">165
                  </h3>
                  <h6 className="pay-sub-p"> Results</h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6  order-1  order-sm-2  pay-per-img-head2">
              <img className="pay-per-img2" src={per4} />
            </div>
          </div>

          {/* <div className="row pay-per-main pt-5 pay-per-bot-pading">
            <div className='bg2-cont' >
              <img className='bg-txt2' src={bg2} alt="" />
            </div>
            <div className="col-12 col-sm-6 pay-per-img-head">
              <img className="pay-per-img" src={per5} />
            </div>
            <div className="col-12 col-sm-6 pay-text-col">
              <img className="pay-per-logo" src={perL5} />
              <h3 className="pay-per-head">Engagement Ad </h3>
              <p className="pay-per-para">Kanavu Koodam Constructions aimed to enhance brand visibility and connect with potential clients. Our campaign showcased their expertise in building dream homes with striking visuals, compelling narratives, and interactive elements that resonated with the audience. </p>
              <div className="pay-sub">
                <div className="pay-sub-box">
                  <h3 className="pay-sub-head">3,000</h3>
                  <h6 className="pay-sub-p">Reaches</h6>
                </div>
                <div className="pay-sub-box">
                  <h3 className="pay-sub-head"> 6,000</h3>
                  <h6 className="pay-sub-p">Impressions</h6>
                </div>
                <div className="pay-sub-box">
                  <h3 className="pay-sub-head">30+</h3>
                  <h6 className="pay-sub-p"> Results</h6>
                </div>
              </div>
            </div>
          </div> */}
          <div>
          <KK />
          </div>

        </div>
        <OurCommunity/>
       <Footerel/>

      </div>
    )
  },
  {
    key: 4,
    content: (
      <div className="key-4" id="top">
        <div className="row ev-contain">
          <div className="col-12 ev-text-head">
            <h3 >Weddings</h3>
            <p>Your wedding day is a once-in-a-lifetime event. Let us create a celebration that reflects your love story.</p>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-8">
                <img className="ev1" src={ev1} />
              </div>
              <div className="col-4">
                <img className="ev2" src={ev2} />
              </div>
            </div>
          </div>
        </div>

        <div className="row ev-contain ev-top">
          <div className="col-12 ev-text-head">
            <h3 >Corporate events</h3>
            <p> Our corporate events are designed to impress and engage. Let us handle the details while you focus on your business.
            </p>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-8">
                <img className="ev1" src={ev4} />
              </div>
              <div className="col-4">
                <img className="ev2" src={ev3} />
              </div>
            </div>
          </div>
        </div>

        <div className="row ev-contain ev-top">
          <div className="col-12 ev-text-head">
            <h3 >Reception</h3>
            <p>Our expert planning ensures your reception is unforgettable. We craft beautiful settings and seamless transitions that impress your guests.</p>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-8">
                <img className="ev1" src={ev6} />
              </div>
              <div className="col-4">
                <img className="ev2" src={ev5} />
              </div>
            </div>
          </div>
        </div>

        <div className="row ev-contain ev-top ev-bottom">
          <div className='bg2-cont' >
            <img className='bg-txt2' src={bg2} alt="" />
          </div>
          <div className="col-12 ev-text-head">
            <h3 >Birthday Parties</h3>
            <p>Make every birthday a memorable one. Our creative team transforms ordinary celebrations into extraordinary experiences.
            </p>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-8">
                <img className="ev1" src={ev7} />
              </div>
              <div className="col-4">
                <img className="ev2" src={ev8} />
              </div>
            </div>
          </div>
        </div>
        <OurCommunity/>
      <Footerel/>

      </div>
    )
  },
  {
    key: 5,
    content: (
      <div className="key-5" id="top">
        <div className="row">

          <div className="col-12">

            <div className="container-fluid gal-containers" id="main-content">
              <div>
                <div className="pv-head pv-gal-row"><h4>Photography</h4>
                  <p>Our portfolio captures life's essence through stunning portraits, breathtaking landscapes, unforgettable moments, and captivating product imagery.</p></div>
                <div className="mt-4">
                  <div className="webdesign-section">
                    <div class="row first-row">
                      <div class="col-6 col-sm-3  poster-padding-col">
                        <img className="web-img" src={pv1} />
                      </div>
                      <div class="col-12 col-sm-6 order-3 order-sm-2 posters-col">
                        <img className="web-img" src={pv2} />
                      </div>
                      <div class="col-6 col-sm-3 order-2 order-sm-3 poster-padding-col">
                        <img className="web-img" src={pv3} />
                      </div>
                    </div>
                    {/* <div class="row second-row">
          <div class="col-8 poster-padding-col">
            <img className="design-img" src={nss1} />
          </div>
          <div class="col-4 poster-padding-col">
            <img className="design-img" src={nss2} />
          </div>
        </div> */}
                    <div class="row second-row">
                      <div class="col-12 col-sm-5 poster-padding-col">
                        <img className="design-img" src={pv4} />
                      </div>
                      <div class="col-6 col-sm-3 poster-padding-col">
                        <img className="design-img2 mt-2 mt-sm-0" src={pv5} />
                      </div>
                      <div class="col-6 col-sm-4 poster-padding-col">
                        <img className="design-img mt-2 mt-sm-0" src={pv6} />
                      </div>
                    </div>
                    <div class="row first-row">
                      <div class="col-6 col-sm-3  poster-padding-col">
                        <img className="design-img" src={pv7} />
                      </div>
                      <div class="col-6 col-sm-3 poster-padding-col">
                        <img className="design-img" src={pv8} />
                      </div>
                      <div class="col-12 col-sm-6 poster-padding-col">
                        
                        <img className="design-img mt-2 mt-sm-0" src={pv9} />
                      </div>
                    </div>
                    {/* <div class="row second-row">
          <div class="col-8 poster-padding-col">
            <img className="design-img" src={laxmi1} />
          </div>
          <div class="col-4 poster-padding-col">
            <img className="design-img" src={laxmi2} />
          </div>
        </div>
        <div class="row first-row">
          <div class="col-4 poster-padding-col">
            <img className="design-img" src={maz1} />
          </div>
          <div class="col-8 poster-padding-col">
            <img className="design-img" src={maz2} />
          </div>
        </div>
        <div class="row second-row">
          <div class="col-7 poster-padding-col">
            <img className="design-img" src={wise1} />
          </div>
          <div class="col-3 poster-padding-col">
            <img className="design-img" src={wise2} />
          </div>
          <div class="col-2 poster-padding-col">
            <img className="design-img" src={wise3} />
          </div>
        </div>
        <div class="row first-row">
          <div class="col-4 poster-padding-col">
            <img className="design-img" src={apr1} />
          </div>
          <div class="col-8 poster-padding-col">
            <img className="design-img" src={apr2} />
          </div>
        </div>
        <div class="row second-row">
          <div class="col-8 poster-padding-col">
            <img className="design-img" src={ana1} />
          </div>
          <div class="col-4 poster-padding-col">
            <img className="design-img" src={ana2} />
          </div>
        </div>
        <div class="row first-row">
          <div class="col-4 poster-padding-col">
            <img className="design-img" src={jat1} />
          </div>
          <div class="col-8 poster-padding-col">
            <img className="design-img" src={jat2} />
          </div>
        </div>
        <div class="row second-row">
          <div class="col-8 poster-padding-col">
            <img className="design-img" src={ssv1} />
          </div>
          <div class="col-4 poster-padding-col">
            <img className="design-img" src={ssv2} />
          </div>
        </div>
        <div class="row first-row">
          <div class="col-6 poster-padding-col">
            <img className="design-img" src={gr1} />
          </div>
          <div class="col-6 poster-padding-col">
            <img className="design-img" src={gr2} />
          </div>
        </div> */}
                  </div>




                </div>

                <div className="pv-head pv-gal-row pb-1 pt-3"><h4>Videography</h4>
                  <p> Our videos tell captivating stories, from dynamic event coverage to cinematic narratives. Let us transport you with every frame.
                  </p></div>
              </div>
              {/* <div className="row pv-gal-row">
                <div className="col-12 col-sm-6 pv-beer mt-2">
                  <video className="port-vedio" controls={true} src={texvid} />
                </div>
                <div className="col-12 col-sm-6 pv-beer mt-2">
                  <video className="port-vedio" controls={true} src={mahvid} />
                </div>
              </div> */}
              <Videos />


            </div>

          </div>

        </div>
        <OurCommunity/>
      <Footerel/>
        

      </div>
    )
  }
];

const GradientStages = [0, 25, 50, 75, 100];

export default class HorizontalSlider extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle,
    sliderValue: GradientStages[0]
  };

  handleSliderChange = (newValue) => {
    const index = GradientStages.indexOf(newValue);
    this.setState({ sliderValue: newValue, goToSlide: index });
  };

  handleSlideChange = (newIndex) => {
    const newValue = GradientStages[newIndex];
    this.setState({ sliderValue: newValue, goToSlide: newIndex });
  };

  render() {
    return (
      <div>
        <div className="slide-creative-container" id="top">
          <div className="carousel-main-container">

            <HorizontalCarousel
              slides={slides}
              offsetRadius={this.state.offsetRadius}
              showNavigation={this.state.showNavigation}
              animationConfig={this.state.config}
              goToSlide={this.state.goToSlide}
              onSlideChange={this.handleSlideChange} />

          </div>
          <div className="Horizontal-scrollbar-container">
            <SliderLine
              currentValue={this.state.sliderValue}
              onSliderChange={this.handleSliderChange} />
          </div>
        </div>

      </div>
    );
  }
}
