import React, { useRef, useState, useEffect } from 'react';
import texvid from '../../../../vedios/tex.mp4';
import mahvid from '../../../../vedios/maha.mp4';
import sw from '../../../../vedios/sw.mp4';
import mg2 from '../../../../vedios/mg2.mp4';
import '../portfolio-sub/Photo.css'; // Import the CSS file

const Videos = () => {
  const texVidRef = useRef(null);
  const mahVidRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [texPlaying, setTexPlaying] = useState(false);
  const [mahPlaying, setMahPlaying] = useState(false);

  useEffect(() => {
    // Event listener for fullscreen change
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        exitFullscreen();
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, [currentVideo]);

  const handleVideoEnd = (videoRef, setPlaying) => {
    setPlaying(false);
    videoRef.current.currentTime = 0; // Reset video to start
  };

  const handleFullscreen = (videoRef) => {
    setCurrentVideo(videoRef.current);
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) { /* Firefox */
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) { /* IE/Edge */
      videoRef.current.msRequestFullscreen();
    }
    videoRef.current.play();
    setPlayingState(videoRef, true);
    setIsFullscreen(true);
  };

  const exitFullscreen = () => {
    if (currentVideo) {
      currentVideo.pause(); // Pause the video
      currentVideo.currentTime = 0; // Reset video to start
      setPlayingState(currentVideo, false);
    }
    setIsFullscreen(false);
    setCurrentVideo(null);
  };

  const setPlayingState = (videoRef, isPlaying) => {
    if (videoRef === texVidRef.current) {
      setTexPlaying(isPlaying);
    } else if (videoRef === mahVidRef.current) {
      setMahPlaying(isPlaying);
    }
  };

  const handlePlayButtonClick = (videoRef) => {
    handleFullscreen(videoRef);
    setPlayingState(videoRef, true);
  };

  return (
    <div>
      <div className='pv-vedio-container'>
        <div className='vedio-sub1'>
          {!texPlaying && (
            <button className='play-buttone' onClick={() => handlePlayButtonClick(texVidRef)}>
              ▶
            </button>
          )}
          <video className='pv-video' ref={texVidRef} src={texvid} controls={false} />
        </div>
        <div className='vedio-sub2'>
          {!mahPlaying && (
            <button className='play-buttone' onClick={() => handlePlayButtonClick(mahVidRef)}>
              ▶
            </button>
          )}
          <video className='pv-video' ref={mahVidRef} src={mahvid} controls={false} />
        </div>
        <div className='vedio-sub2'>
          {!mahPlaying && (
            <button className='play-buttone' onClick={() => handlePlayButtonClick(mahVidRef)}>
              ▶
            </button>
          )}
          <video className='pv-video' ref={mahVidRef} src={sw} controls={false} />
        </div>
        <div className='vedio-sub2'>
          {!mahPlaying && (
            <button className='play-buttone' onClick={() => handlePlayButtonClick(mahVidRef)}>
              ▶
            </button>
          )}
          <video className='pv-video' ref={mahVidRef} src={mg2} controls={false} />
        </div>
      </div>
      
      
    </div>
  );
};

export default Videos;
