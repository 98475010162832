import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';
import { Route, Routes } from 'react-router-dom';

import Homee from './Component/Page1/Home/Home';
import Navbare from './Component/Page1/navbar/navbar';
import Aboute from './Component/Page2/Aboutus/About';
import Footerel from './Component/Page2/Lfooter/Lfooter';
import Services from './Component/Page3/Services';
import Blog from './Component/Page5/Blog/Blog';
import Careers from './Component/Page6/Careers';
import Reachus from './Component/Page7/Reach';
import Portfolioo from './Component/Page4/Portfolio';
import Arrow from './Component/Page1/Arrow/Arrow';
import Whatsapp from './Component/Whatsapp/Whatsapp';

import Preloadere from './Component/Preloaderr/loader';
import Testslider from './Component/Page2/testing-slider/test-slider';
import Tscroll from './Component/Page1/Test-scroll/Tscroll';
import ModalCarousel from './Component/Page4/Carouselee/omb/Omb_cr';
import Appe from './Component/Page4/Carouselee/omb/Modal';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay
  }, []);

  return (
    <div className='app'>
      {loading ? (
        <Preloadere/>
      ) : (
        <>
          <Navbare />
          <Routes>
            <Route path="/" element={<Homee />} />
            <Route path="/about" element={<Aboute />} />
            <Route path="/services" element={<Services />} />
            <Route path="/portfolio" element={<Portfolioo />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/reach" element={<Reachus />} />
          </Routes>
          <Arrow />
          <Whatsapp />
         
        
          
        </>
      )}
     
    </div>
  );
}

export default App;
